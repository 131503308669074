html {
    height: 100%;
}

.body-box{
position: absolute;
display: flex;
top: 50%;
left: 50%;
width: 700px;
padding: 40px;
transform: translate(-50%, -50%);
background: #00000072;
box-sizing: border-box;
box-shadow: 0 15px 25px rgba(0,0,0,.6);
border-radius: 50px;
user-select: none;
}

.setup-box {
width: 400px;
padding: 40px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
box-sizing: border-box;
user-select: none;

}

.picture-box{
background-image: url('../../../../shared/assets/logo.png');
background-position: center;
width: 300px;
box-sizing: border-box;
box-shadow: 0 15px 25px rgba(0,0,0,.6);
border-radius: 50px;
user-select: none;
}

.setup-box h2 {
margin: 0 0 30px;
padding: 0;
color: #fff;
text-align: center;
user-select: none;
}

.setup-box .user-box {
position: relative;
user-select: none;
}

.setup-box .user-box input {
width: 100%;
padding: 10px 0;
font-size: 16px;
color: #ffffff;
margin-bottom: 30px;
border: none;
border: 1px solid #989696;
border-radius: 20px;
background: transparent;
text-align: center;
user-select: none;
}
.setup-box .user-box input:focus::placeholder{
color: transparent;
}
.setup-box .user-box label {
position: absolute;
top: 0;
bottom: 0;
left: 0;
padding: 10px 0;
font-size: 16px;
color: #fff;
pointer-events: none;
transition: .5s;
user-select: none;
}

.setup-box .user-box input:focus ~ label,
.setup-box .user-box input:valid ~ label {
top: -30px;
left: 0;
color: #03e9f4;
font-size: 12px;
user-select: none;
}


.inputCheck{
float: left;
user-select: none;
}
.checkLabel{
float: left;
padding-left: 10px;
font-family: sans-serif;
color: #989696;
font-size: 16px;
user-select: none;
}

input.inputButton {
border: none;
background: cornflowerblue;
color: white;
padding: 12px 54px;
margin: 8px;
margin-top: 20px;
font-size: 15px;
border-radius: 25px;
cursor: pointer;
user-select: none;
}

.code {
font-size: 38px;
}

.navBar {
    display: flex;
    padding-left: 20px;
    align-items: center;
    align-content: space-around;
  }
  
  .navButton {
    border: none;
    background: #b1bfff;
    color: black;
    padding: 12px 54px;
    margin: 8px;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
  }
  
  img {
    margin: 1rem 0;
  }  